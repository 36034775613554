import { AiSearchResponse } from "@/lib/archie";
import {
  Box,
  Card,
  CardBody,
  CardHeader,
  Divider,
  Heading,
  Hide,
  HStack,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverContent,
  PopoverFooter,
  PopoverTrigger,
  Show,
  Tag,
  Text,
  Link as ChakraLink,
} from "@chakra-ui/react";
import { useEffect } from "react";

import useArchieData from "@/hooks/useArchieData";

import ArchieSumm from "./Archie/ArchieSUMM";
import ArchieYO from "./Archie/ArchieYO";
import ArchieQ from "./Archie/ArchieQ";
import { useTranslation } from "react-i18next";
import Beta from "@/ui/Beta";
import { Link } from "react-router-dom";

const Archie = ({ data }: { data: AiSearchResponse }) => {
  const {
    currentQuery,
    setCurrentQuery,
    setArchieData,
    achieSUMM,
    papers,
    reset,
    displayed,
  } = useArchieData((state) => state);

  const { t } = useTranslation();

  useEffect(() => {
    if (currentQuery === data.query) {
      return;
    }
    const fetchArchie = async () => {
      reset();
      setArchieData({
        papers: Object.values(data.results),
        language: data.language,
      });
      setCurrentQuery(data.query);
    };

    fetchArchie();
  }, [
    data.query,
    setArchieData,
    data.language,
    data.results,
    currentQuery,
    reset,
    setCurrentQuery,
  ]);

  return (
    <Box display={"flex"} flexDirection={"column"} w={"100%"} gap={4} mb={12}>
      {Object.values(displayed).some((e) => e) && (
        <Popover trigger="hover">
          <PopoverTrigger>
            <Tag
              bgColor="brand.500"
              color={"#FFF"}
              fontWeight={"bold"}
              alignSelf={"flex-start"}
              size={"lg"}
            >
              Archie
            </Tag>
          </PopoverTrigger>
          <PopoverContent>
            <PopoverArrow />
            <PopoverBody>
              <Text fontWeight={"bold"}>{t("archie.title")}</Text>
              <Text>{t("archie.description")}</Text>
            </PopoverBody>
            <PopoverFooter>
              <ChakraLink
                as={Link}
                color={"brand.500"}
                textDecoration={"underline"}
                to="/help"
              >
                {t("archie.learnMore")}
              </ChakraLink>
            </PopoverFooter>
          </PopoverContent>
        </Popover>
      )}

      {displayed.insights && (
        <Card bgColor={"#fff"} px={[4, 8]} borderRadius={24}>
          <CardHeader pb={0}>
            <HStack gap={4}>
              <Heading as={"h3"} size={"md"}>
                {t("archie.insights")}
              </Heading>
              <Beta />
            </HStack>
          </CardHeader>
          <CardBody
            display={"flex"}
            flexDirection={[
              "column-reverse",
              "column-reverse",
              "column-reverse",
              "column-reverse",
              "row",
            ]}
            gap={6}
            alignItems={"flex-start"}
          >
            <Box flexGrow={1}>
              <ArchieQ />
            </Box>

            <Hide below={"xl"}>
              <Divider orientation={"vertical"} />
            </Hide>
            <Show below={"xl"}>
              <Divider orientation="horizontal" />
            </Show>
            <Box maxW={["100%", "100%", "100%", "100%", "400px"]}>
              <ArchieYO />{" "}
            </Box>
          </CardBody>
        </Card>
      )}
      {displayed.summary && (
        <Card px={[4, 8]} borderRadius={24}>
          <CardHeader pb={0}>
            <HStack gap={4} mb={4}>
              <Heading as={"h3"} size={"md"} m={0}>
                {t("archie.summary")}
              </Heading>
              <Beta />
            </HStack>
            {achieSUMM && (
              <Tag mt={1}>
                {t("archie.papersAnalysed", {
                  count: papers.length,
                })}
              </Tag>
            )}
          </CardHeader>
          <CardBody>
            <ArchieSumm />
          </CardBody>
        </Card>
      )}
    </Box>
  );
};

export default Archie;

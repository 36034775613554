import { useUserSession } from "@/hooks/useUserSession";
import { Summary } from "@/lib/SemanticScholarApi";
import { dbIa, functions } from "@/lib/firebase";
import { ChevronDownIcon } from "@chakra-ui/icons";
import {
  Box,
  Button,
  Heading,
  ListItem,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  OrderedList,
  SkeletonText,
  Text,
  VStack,
  useToast,
} from "@chakra-ui/react";
import {
  Timestamp,
  collection,
  doc,
  getDoc,
  getDocs,
} from "firebase/firestore";
import { httpsCallable } from "firebase/functions";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import CreateOpenSummary from "./CreateOpenSummary";

type SummaryDocs = {
  [key: string]: SummaryDoc;
};
type SummaryDoc = {
  createdAt: Timestamp;
  summary: Summary;
  translationCost?: number;
};
type Lang = "base" | "en-US" | "fr";
const SummaryTab = (props: {
  corpusId: string;
  openAccesspdf: {
    url: string;
  } | null;
}) => {
  const { corpusId, openAccesspdf } = props;
  const { user } = useUserSession();
  const [summary, setSummary] = useState<SummaryDocs | null>(null);
  const [availableLangs, setAvailableLangs] = useState<Lang[]>(["base"]);
  const [loading, setLoading] = useState(true);
  const [currentLang, setCurrentLang] = useState<Lang>("base");
  const toast = useToast();

  const loadSummaries = async () => {
    if (!user) return;
    const [summaryDocs, availableLangs] = await Promise.all([
      getDocs(collection(dbIa, "articles_summaries", `${corpusId}`, "langs")),
      getDoc(doc(user.ref, "summaries", `${corpusId}`)),
    ]);

    if (summaryDocs.empty || !availableLangs.exists()) {
      setLoading(false);
      return;
    }

    const summaryDocsData: SummaryDocs = {};
    summaryDocs.forEach((doc) => {
      summaryDocsData[doc.id] = doc.data() as SummaryDoc;
    });

    const availableLangsData = availableLangs.data()
      .availableLanguages as Lang[];

    setAvailableLangs(availableLangsData);
    setSummary(summaryDocsData);

    setLoading(false);
  };

  const { t } = useTranslation();
  useEffect(() => {
    (async () => {
      if (!user) return;
      const [summaryDocs, availableLangs] = await Promise.all([
        getDocs(collection(dbIa, "articles_summaries", `${corpusId}`, "langs")),
        getDoc(doc(user.ref, "summaries", `${corpusId}`)),
      ]);

      if (summaryDocs.empty || !availableLangs.exists()) {
        setLoading(false);
        return;
      }

      const summaryDocsData: SummaryDocs = {};
      summaryDocs.forEach((doc) => {
        summaryDocsData[doc.id] = doc.data() as SummaryDoc;
      });

      const availableLangsData = availableLangs.data()
        .availableLanguages as Lang[];

      setAvailableLangs(availableLangsData);
      setSummary(summaryDocsData);

      setLoading(false);
    })();
  }, [corpusId, user]);

  const translateSummary = httpsCallable<
    {
      corpusId: string;
      target_lang: string;
    },
    SummaryDoc
  >(functions, "translateSummary");

  const onLangChange = async (lang: Lang) => {
    if (availableLangs.includes(lang)) {
      setCurrentLang(lang);
    } else {
      if (
        user?.paperCoins &&
        user.paperCoins < (summary?.base.translationCost ?? 0) &&
        user.currentPlan !== "premium"
      ) {
        toast({
          title: t("article.notEnoughCoins"),
          status: "error",
        });
      }
      try {
        setLoading(true);
        const { data } = await translateSummary({
          corpusId: corpusId,
          target_lang: lang,
        });
        setSummary((prev) => {
          if (prev === null) return { [lang]: data };
          prev[lang] = data;
          return {
            ...prev,
            [lang]: data,
          };
        });
        setAvailableLangs((prev) => [...prev, lang]);
        setCurrentLang(lang);
      } catch (error) {
        console.error(error);
      } finally {
        setLoading(false);
      }
    }
  };

  if (loading) {
    return <SkeletonText noOfLines={5} spacing={4} skeletonHeight={4} />;
  }

  return summary ? (
    <VStack spacing={0}>
      <Menu>
        <MenuButton ml={"auto"} as={Button} rightIcon={<ChevronDownIcon />}>
          {t("article.lang")}
        </MenuButton>
        <MenuList>
          <MenuItem onClick={() => onLangChange("base")}>
            {t("article.langs.base")}
          </MenuItem>
          <MenuItem onClick={() => onLangChange("en-US")}>
            {t("article.langs.en")}{" "}
            {availableLangs.includes("en-US")
              ? ""
              : `(${summary.base.translationCost} PC)`}
          </MenuItem>
          <MenuItem onClick={() => onLangChange("fr")}>
            {t("article.langs.fr")}{" "}
            {availableLangs.includes("fr")
              ? ""
              : `(${summary.base.translationCost} PC)`}
          </MenuItem>
        </MenuList>
      </Menu>

      {summary[currentLang].summary.map((part) => (
        <Box key={part.title} mb={4}>
          <Heading size={"md"} mb={2} mt={2} as={"h2"}>
            {part.title}
          </Heading>
          {part.value.map((value) => (
            <Text mb={2} key={value.text}>
              {value.text}
            </Text>
          ))}
        </Box>
      ))}
    </VStack>
  ) : (
    <Box display={"flex"} flexDir={"column"} alignItems={"center"} gap={4}>
      <Heading as={"h2"} mb={2}>
        {t("article.noSummary")}
      </Heading>

      {openAccesspdf ? (
        <>
          <Text>{t("article.openSummary")}</Text>
          <CreateOpenSummary
            corpusId={corpusId}
            loadSummaries={loadSummaries}
          />
        </>
      ) : (
        <>
          <OrderedList transform={["none", "translateX(8%)"]}>
            <ListItem>{t("article.createSummary1")}</ListItem>
            <ListItem>{t("article.createSummary2")}</ListItem>
            <ListItem>{t("article.createSummary3")}</ListItem>
          </OrderedList>

          <Button as={Link} to={"/ia"} colorScheme={"brand"}>
            {t("article.createSummaryButton")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default SummaryTab;

import {
  Heading,
  Tag,
  HStack,
  SkeletonText,
  Box,
  VStack,
  Text,
  IconButton,
  Checkbox,
  Flex,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";

import useArchieData from "@/hooks/useArchieData";
import { useEffect, useState } from "react";
import { runArchieYO } from "@/lib/cloudFunctions";
import { CheckIcon, CloseIcon, MinusIcon } from "@chakra-ui/icons";
import { IoFilterOutline } from "react-icons/io5";

const ArchieYO = () => {
  const { t } = useTranslation();
  const {
    papers,
    currentQuery,
    archieYO,
    language,
    setArchieData,
    toggleFilterByAnswer,
    filterByAnswer,
    setSelectedAnswers,
    selectedAnswer,
  } = useArchieData((state) => state);
  const [loading, setLoading] = useState(false);
  useEffect(() => {
    if (currentQuery === "" || archieYO) {
      return;
    }
    const fetchArchieYO = async () => {
      setLoading(true);
      const res = await runArchieYO({
        query: currentQuery,
        papers: papers,
        language: language,
      });
      setArchieData({ archieYO: res.data });
      setLoading(false);
    };

    fetchArchieYO();
  }, [currentQuery, archieYO, language, papers, setArchieData]);
  if (loading) return <SkeletonText noOfLines={4} spacing={4} minW={"400px"} />;
  if (!archieYO) return null;

  const relevantPapersLength =
    archieYO.answer.no.length +
    archieYO.answer.yes.length +
    archieYO.answer.potentially.length;
  return (
    <Box minW={["auto", "auto", "auto", "auto", "400px"]}>
      <Tag mt={1}>
        {t("archie.papersAnalysed", {
          count: relevantPapersLength,
        })}
      </Tag>
      <HStack mt={4} gap={4} justifyContent={"space-between"}>
        <Heading
          as={"h3"}
          size={"sm"}
          textAlign={"left"}
          sx={{
            textWrap: "balance",
          }}
        >
          {archieYO.query}
        </Heading>

        <IconButton
          aria-label="Filter by answer"
          variant={"ghost"}
          icon={<IoFilterOutline size={26} />}
          onClick={toggleFilterByAnswer}
        />
      </HStack>
      {relevantPapersLength > 0 ? (
        <Flex gap={2} flexWrap={"wrap"}>
          <VStack mt={4} spacing={4} align={"stretch"}>
            <HStack spacing={2} justify={"space-between"}>
              <HStack spacing={2}>
                {filterByAnswer ? (
                  <Checkbox
                    isChecked={selectedAnswer.yes}
                    onChange={() =>
                      setSelectedAnswers({
                        ...selectedAnswer,
                        yes: !selectedAnswer.yes,
                      })
                    }
                  />
                ) : (
                  <CheckIcon color={"green.500"} />
                )}

                <Text width={"18ch"} fontWeight={"bold"}>
                  {t("archie.yes_no.yes")} -{" "}
                  {(
                    (archieYO.answer.yes.length / relevantPapersLength) *
                    100
                  ).toFixed(0)}
                  %
                </Text>
              </HStack>
            </HStack>
            <HStack spacing={2} justify={"space-between"}>
              <HStack spacing={2}>
                {filterByAnswer ? (
                  <Checkbox
                    isChecked={selectedAnswer.potentially}
                    onChange={() =>
                      setSelectedAnswers({
                        ...selectedAnswer,
                        potentially: !selectedAnswer.potentially,
                      })
                    }
                  />
                ) : (
                  <MinusIcon color={"yellow.500"} />
                )}
                <Text width={"18ch"} fontWeight={"bold"}>
                  {t("archie.yes_no.potentially")} -{" "}
                  {(
                    (archieYO.answer.potentially.length /
                      relevantPapersLength) *
                    100
                  ).toFixed(0)}
                  %
                </Text>
              </HStack>
            </HStack>
            <HStack spacing={2} justify={"space-between"}>
              <HStack spacing={2}>
                {filterByAnswer ? (
                  <Checkbox
                    isChecked={selectedAnswer.no}
                    onChange={() =>
                      setSelectedAnswers({
                        ...selectedAnswer,
                        no: !selectedAnswer.no,
                      })
                    }
                  />
                ) : (
                  <CloseIcon color={"red.500"} />
                )}

                <Text width={"18ch"} fontWeight={"bold"}>
                  {t("archie.yes_no.no")} -{" "}
                  {(
                    (archieYO.answer.no.length / relevantPapersLength) *
                    100
                  ).toFixed(0)}
                  %
                </Text>
              </HStack>
            </HStack>
          </VStack>

          <ResponsiveContainer height={150} width={150}>
            <PieChart>
              <Pie
                data={[
                  {
                    name: t("archie.yes_no.yes"),
                    value: archieYO.answer.yes.length,
                  },
                  {
                    name: t("archie.yes_no.potentially"),
                    value: archieYO.answer.potentially.length,
                  },
                  {
                    name: t("archie.yes_no.no"),
                    value: archieYO.answer.no.length,
                  },
                ]}
                dataKey="value"
                nameKey="name"
                paddingAngle={5}
                innerRadius={45}
                outerRadius={65}
                isAnimationActive
                fill="#8884d8"
              >
                <Cell fill={"#38A169"} />
                <Cell fill={"#D69E2E"} />
                <Cell fill={"#E53E3E"} />
              </Pie>
            </PieChart>
          </ResponsiveContainer>
        </Flex>
      ) : (
        <Text mt={4}>{t("archie.noRelevantPapers")}</Text>
      )}
    </Box>
  );
};

export default ArchieYO;

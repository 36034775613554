import Container from "@/ui/Container";
import MainCard from "@/ui/MainCard";
import {
  Heading,
  ListItem,
  OrderedList,
  Text,
  UnorderedList,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t } = useTranslation();
  return (
    <Container hasSearch>
      <MainCard
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#FFF"}
      >
        <Heading as={"h1"} size={"2xl"} textAlign={"center"}>
          {t("howItWorks.title")}
        </Heading>
        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.subtitle1")}
        </Heading>
        <Text>{t("howItWorks.text1")}</Text>
        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.subtitle2")}
        </Heading>
        <Text>{t("howItWorks.text2")}</Text>
        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.subtitle3")}
        </Heading>
        <Text>{t("howItWorks.text3")}</Text>
        <UnorderedList>
          <ListItem>{t("howItWorks.ia1")}</ListItem>
          <ListItem>{t("howItWorks.ia2")}</ListItem>
          <ListItem>{t("howItWorks.ia3")}</ListItem>
        </UnorderedList>
        <Text>{t("howItWorks.text4")}</Text>

        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.subtitle4")}
        </Heading>
        <Text>{t("howItWorks.text5")}</Text>
        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.subtitle5")}
        </Heading>
        <Text>{t("howItWorks.text6")}</Text>
        <OrderedList>
          <ListItem>{t("howItWorks.ia4")}</ListItem>
          <ListItem>{t("howItWorks.ia5")}</ListItem>
        </OrderedList>
        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.subtitle6")}
        </Heading>
        <Text>
          <strong>{t("howItWorks.text7")}</strong> {t("howItWorks.text8")}
        </Text>
        <Text>
          <strong>{t("howItWorks.text9")}</strong> {t("howItWorks.text10")}
        </Text>
        <Text>
          <strong>{t("howItWorks.text11")}</strong> {t("howItWorks.text12")}
        </Text>
        <Text>
          <strong>{t("howItWorks.text13")}</strong> {t("howItWorks.text14")}
        </Text>

        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.archie.title")}
        </Heading>
        <Text>{t("howItWorks.archie.text1")}</Text>
        <Text>{t("howItWorks.archie.text2")}</Text>
        <Text>
          <strong>{t("howItWorks.archie.text3")}</strong>
        </Text>
        <OrderedList>
          <ListItem>
            <strong>{t("howItWorks.archie.ia1")}</strong>{" "}
            {t("howItWorks.archie.ia2")}
          </ListItem>
          <ListItem>
            <strong>{t("howItWorks.archie.ia3")}</strong>{" "}
            {t("howItWorks.archie.ia4")}
          </ListItem>
          <ListItem>
            <strong>{t("howItWorks.archie.ia5")}</strong>{" "}
            {t("howItWorks.archie.ia6")}
          </ListItem>
          <ListItem>
            <strong>{t("howItWorks.archie.ia8")}</strong>{" "}
            {t("howItWorks.archie.ia7")}
          </ListItem>
        </OrderedList>
        <Text>
          <strong>{t("howItWorks.archie.text4")}</strong>
        </Text>
        <Text>{t("howItWorks.archie.text5")}</Text>

        <Heading as={"h2"} size={"lg"}>
          {t("howItWorks.archie.title2")}
        </Heading>
        <UnorderedList>
          <ListItem>
            <strong>{t("howItWorks.archie.why1")}</strong>{" "}
            {t("howItWorks.archie.why2")}
          </ListItem>
          <ListItem>
            <strong>{t("howItWorks.archie.why3")}</strong>{" "}
            {t("howItWorks.archie.why4")}
          </ListItem>
          <ListItem>
            <strong>{t("howItWorks.archie.why5")}</strong>{" "}
            {t("howItWorks.archie.why6")}
          </ListItem>
          <ListItem>
            <strong>{t("howItWorks.archie.why7")}</strong>{" "}
            {t("howItWorks.archie.why8")}
          </ListItem>
        </UnorderedList>
        <Text>{t("howItWorks.archie.text6")}</Text>
      </MainCard>
    </Container>
  );
};

export default HowItWorks;

import { SearchResponseItem } from "@/lib/SemanticScholarApi";
import ArchieSource from "./ArchieSource";

//TODO : Refactor, no need for the baseIndex parameter and the currentSource variable

const parseText = (
  text: string,
  papers: SearchResponseItem[],
  baseIndex = 1
) => {
  const regex = /\[(\d+)\]/g;
  const parts: (string | JSX.Element)[] = [];
  let match: RegExpExecArray | null;
  let lastIndex = 0;
  let currentSource = baseIndex;
  while ((match = regex.exec(text)) !== null) {
    // Ajouter le texte avant le match actuel
    if (match.index > lastIndex) {
      parts.push(text.substring(lastIndex, match.index));
    }

    // Ajouter le composant de substitution pour le match actuel

    parts.push(<ArchieSource papers={papers} id={match[1]} key={lastIndex} />);
    currentSource++;

    // Mettre à jour le lastIndex pour le prochain tour de boucle
    lastIndex = regex.lastIndex;
  }

  // Ajouter le reste du texte après le dernier match
  if (lastIndex < text.length) {
    parts.push(text.substring(lastIndex));
  }
  return { parts, currentSource };
};
export default parseText;

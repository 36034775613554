import { useUserSession } from "@/hooks/useUserSession";
import semanticScholarApi, { PaperResponse } from "@/lib/SemanticScholarApi";

import SearchArticle from "@/ui/SearchArticle";

import { Box, CircularProgress, Heading, VStack } from "@chakra-ui/react";

import { collection, getDocs, orderBy, query } from "firebase/firestore";
import { useEffect, useState } from "react";

import { useTranslation } from "react-i18next";

const Summaries = () => {
  const { t } = useTranslation();
  const { user } = useUserSession();

  const [articles, setArticles] = useState<PaperResponse[]>([]);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (!user) return;
    (async () => {
      setLoading(true);
      console.log(user.id);
      const { docs: usersSummaries } = await getDocs(
        query(collection(user.ref, "summaries"), orderBy("created_at", "desc"))
      );

      const corpusIds = usersSummaries.map((doc) => `corpusId:${doc.id}`);

      //filter out repeated corpusIds
      const uniqueCorpusIds = Array.from(new Set(corpusIds));
      if (uniqueCorpusIds.length > 0) {
        const res = await semanticScholarApi.search.post(
          "/paper/batch",
          {
            ids: uniqueCorpusIds,
          },
          {
            params: {
              fields:
                "title,authors,journal,externalIds,tldr,year,publicationDate,isOpenAccess,citationCount,corpusId",
            },
          }
        );

        const articles = res.data.filter(
          (article: PaperResponse | null) => !!article
        );

        setArticles(articles);
      }
      setLoading(false);
    })();
  }, [user]);
  return (
    <Box
      flex={1}
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      borderRadius={32}
      paddingX={[4, 4, 12]}
      paddingY={[2, 4]}
      bgColor={"white"}
    >
      <Heading mb={4} textAlign={"center"}>
        {t("profile.menu.summaries")}
      </Heading>
      <CircularProgress
        isIndeterminate={loading}
        hidden={!loading}
        color="brand.500"
      />
      <VStack spacing={4}>
        {articles.map((article) => (
          <SearchArticle key={article.paperId} {...article} />
        ))}
      </VStack>
    </Box>
  );
};

export default Summaries;

import { createContext, useContext, useEffect, useState } from "react";
import { useUserSession } from "./useUserSession";
import {
  addDoc,
  collection,
  deleteDoc,
  doc,
  onSnapshot,
  updateDoc,
} from "firebase/firestore";
import { User } from "@/lib/types";

import { AddIcon, DeleteIcon } from "@chakra-ui/icons";
import {
  Button,
  Checkbox,
  Drawer,
  DrawerBody,
  DrawerCloseButton,
  DrawerContent,
  DrawerFooter,
  DrawerHeader,
  DrawerOverlay,
  IconButton,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  useDisclosure,
} from "@chakra-ui/react";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";

type ToDo = {
  id: string;
  title: string;
  completed: boolean;
};

type TodoListContextType = {
  todoList: ToDo[];
  addTodo: (title: string) => Promise<void>;
  removeTodo: (id: string) => Promise<void>;
  toggleTodo: (id: string) => Promise<void>;
  clearTodos: () => Promise<void>;
  openTodos: () => void;
  closeTodos: () => void;
};
const TodoListContext = createContext<TodoListContextType>({
  todoList: [],
  addTodo: async () => {},
  removeTodo: async () => {},
  toggleTodo: async () => {},
  clearTodos: async () => {},
  openTodos: () => {},
  closeTodos: () => {},
});

const TodoListContextProvider = ({
  children,
}: {
  children: React.ReactNode;
}) => {
  const [todoList, setTodoList] = useState<ToDo[]>([]);
  const { user } = useUserSession() as { user: User };
  const { isOpen, onClose, onOpen } = useDisclosure();
  const { register, handleSubmit, reset } = useForm<{
    title: string;
  }>();
  const { t } = useTranslation();

  useEffect(() => {
    if (!user) return;
    const usub = onSnapshot(collection(user.ref, "todos"), (snapshot) => {
      setTodoList(
        snapshot.docs.map((doc) => {
          const data = doc.data();
          return {
            id: doc.id,
            title: data.title,
            completed: data.completed,
          };
        })
      );
    });
    return () => {
      usub();
    };
  }, [user]);

  const addTodo = async (title: string) => {
    await addDoc(collection(user.ref, "todos"), {
      title,
      completed: false,
    });
  };

  const removeTodo = async (id: string) => {
    await deleteDoc(doc(user.ref, "todos", id));
  };

  const toggleTodo = async (id: string) => {
    await updateDoc(doc(user.ref, "todos", id), {
      completed: !todoList.find((todo) => todo.id === id)?.completed,
    });
  };
  const clearTodos = async () => {
    await Promise.all(
      todoList.map((todo) => deleteDoc(doc(user.ref, "todos", todo.id)))
    );
  };
  return (
    <TodoListContext.Provider
      value={{
        todoList,
        addTodo,
        removeTodo,
        toggleTodo,
        clearTodos,
        openTodos: onOpen,
        closeTodos: onClose,
      }}
    >
      {children}
      <Drawer isOpen={isOpen} placement="left" onClose={onClose}>
        <DrawerOverlay />
        <DrawerContent>
          <DrawerCloseButton />
          <DrawerHeader>{t("todos.myTodos")}</DrawerHeader>
          <DrawerBody>
            <form
              onSubmit={handleSubmit((data) => {
                addTodo(data.title);
                reset();
              })}
            >
              <InputGroup>
                <Input
                  {...register("title", { required: true })}
                  type="text"
                  placeholder={t("todos.todoTitle")}
                />
                <InputRightAddon>
                  <IconButton
                    aria-label="Add todo"
                    type="submit"
                    icon={<AddIcon />}
                  />
                </InputRightAddon>
              </InputGroup>
            </form>
            {todoList.map((todo) => (
              <Stack
                direction="row"
                justifyContent={"space-between"}
                key={todo.id}
                alignItems={"center"}
                my={2}
              >
                <Checkbox
                  checked={todo.completed}
                  onChange={() => {
                    toggleTodo(todo.id);
                  }}
                >
                  {todo.title}
                </Checkbox>
                <IconButton
                  aria-label="Delete Todo"
                  colorScheme="ghost"
                  icon={<DeleteIcon color={"tomato"} />}
                  onClick={() => {
                    removeTodo(todo.id);
                  }}
                />
              </Stack>
            ))}
          </DrawerBody>
          <DrawerFooter>
            <Button
              display={todoList.length ? "block" : "none"}
              rightIcon={<DeleteIcon />}
              onClick={clearTodos}
              colorScheme="white"
              bgColor={"tomato"}
            >
              {t("todos.clearTodos")}
            </Button>
          </DrawerFooter>
        </DrawerContent>
      </Drawer>
    </TodoListContext.Provider>
  );
};

const useToDoList = () => useContext(TodoListContext);

export { TodoListContext, useToDoList };

export default TodoListContextProvider;

import { Box, Skeleton } from "@chakra-ui/react";

const SearchLoading = () => {
  return (
    <Box w={"100%"} gap={4} p={2} display={"flex"} flexDirection={"column"}>
      {Array.from({ length: 10 }).map((_, index) => (
        <Skeleton
          w={"100%"}
          h={"100px"}
          key={index}
          bgColor={"#fff"}
          borderRadius={28}
          p={2}
        />
      ))}
    </Box>
  );
};

export default SearchLoading;

import { httpsCallable } from "firebase/functions";
import { functions } from "./firebase";
import {
  AiSearchResponse,
  ArchieQRequest,
  ArchieQResponse,
  ArchieSubsequentRequest,
  ArchieSummResponse,
  ArchieYOResponse,
} from "./archie";

const aiSearch = httpsCallable<
  {
    query: string;
    year_range?: [number, number];
    selected_fields_of_study?: string[];
    is_open_access?: boolean;
    influential_citation_filter?: boolean;
  },
  AiSearchResponse
>(functions, "ai_search");

const runArchieSumm = httpsCallable<
  ArchieSubsequentRequest,
  ArchieSummResponse
>(functions, "run_archie_summ");

const runArchieYO = httpsCallable<ArchieSubsequentRequest, ArchieYOResponse>(
  functions,
  "run_archie_yo"
);

const runArchieQ = httpsCallable<ArchieQRequest, ArchieQResponse>(
  functions,
  "run_archie_q"
);

export { aiSearch, runArchieSumm, runArchieYO, runArchieQ };

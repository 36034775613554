import {
  Card,
  CardBody,
  CardHeader,
  Heading,
  Link as ChackraLink,
  Text,
  CardFooter,
} from "@chakra-ui/react";

import { Link } from "react-router-dom";
import Tldr from "./Tldr";
import { PaperResponse, SearchResponseItem } from "@/lib/SemanticScholarApi";
import slugify from "slugify";

import useArchieData from "@/hooks/useArchieData";
import SearchArticleAnswer from "./SearchArticleAnswer";

const SearchArticle = (props: SearchResponseItem | PaperResponse) => {
  const {
    title,
    tldr,

    journal,
    authors,
    externalIds,
    citationCount,
    publicationDate,
    year,
    corpusId,
  } = props;

  const { displayed, archieYO } = useArchieData((state) => state);

  const slug = slugify(title);

  return (
    <ChackraLink
      id={`paper-${corpusId}`}
      sx={{
        _hover: {
          textDecoration: "none",
        },
      }}
      as={Link}
      to={`/article/${slug}/${corpusId}`}
      width={"100%"}
    >
      <Card bgColor={"#fff"} borderRadius={28} p={2} minH={200}>
        <CardHeader
          pb={0}
          display={"flex"}
          gap={4}
          justifyContent={"space-between"}
          alignItems={"flex-start"}
          w={"100%"}
        >
          <Heading
            fontFamily={"Be Vietnam Pro"}
            fontWeight={900}
            as={"h2"}
            size={"md"}
          >
            {title}
          </Heading>
          {displayed.aiSearch && displayed.insights && archieYO && (
            <SearchArticleAnswer corpusId={corpusId} />
          )}
        </CardHeader>
        <CardBody
          justifyContent={"center"}
          display={"flex"}
          flexDirection={"column"}
        >
          {tldr && (
            <Text>
              <Tldr />
              {tldr.text}
            </Text>
          )}
        </CardBody>
        <CardFooter pt={0}>
          <Text fontSize={12} fontStyle={"italic"}>
            {publicationDate
              ? new Date(publicationDate).toLocaleDateString()
              : year}{" "}
            | {journal?.name} |{" "}
            {authors
              .slice(0, 1)
              .map((author) => author.name)
              .join(", ")}{" "}
            et al. | {externalIds.DOI} | {citationCount} citations
          </Text>
        </CardFooter>
      </Card>
    </ChackraLink>
  );
};

export default SearchArticle;

import { Box, Heading, SkeletonText, Text } from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";
import { useEffect, useState } from "react";
import { runArchieSumm } from "@/lib/cloudFunctions";

const ArchieSumm = () => {
  const { achieSUMM, setArchieData, currentQuery, papers, language } =
    useArchieData((state) => state);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    if (currentQuery === "" || achieSUMM) {
      return;
    }
    const fetchArchieSumm = async () => {
      setLoading(true);
      const res = await runArchieSumm({
        query: currentQuery,
        papers: papers,
        language: language,
      });
      setArchieData({ achieSUMM: res.data });

      setLoading(false);
    };

    fetchArchieSumm();
  }, [currentQuery, achieSUMM, language, papers, setArchieData]);

  if (loading) return <SkeletonText noOfLines={2} spacing={4} mb={4} />;
  if (!achieSUMM) return null;

  let sourceCount = 1;

  return (
    <Box>
      <Box flexGrow={1} mb={4}>
        {achieSUMM.summary.sections.map((section, index) => {
          const { parts, currentSource } = parseText(
            section.content,
            papers,
            sourceCount
          );
          sourceCount = currentSource;

          return (
            <div key={index}>
              <Heading as={"h4"} size={"md"} my={4}>
                {section.section_title}
              </Heading>
              {section.content ? (
                <Text>{parts.map((e) => e)}</Text>
              ) : (
                <>
                  {section.sub_sections.map((sub_section, sub_index) => {
                    const { parts, currentSource } = parseText(
                      sub_section.content,
                      papers,
                      sourceCount
                    );
                    sourceCount = currentSource;
                    return (
                      <div key={sub_index}>
                        <Heading as={"h5"} size={"sm"} my={3}>
                          {sub_section.sub_section_title}
                        </Heading>
                        <Text>{parts.map((e) => e)}</Text>
                      </div>
                    );
                  })}
                </>
              )}
            </div>
          );
        })}
      </Box>
    </Box>
  );
};

export default ArchieSumm;

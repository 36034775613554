import Archie from "@/components/search/Archie";
import SearchActions from "@/components/search/SearchActions";
import SearchFilters from "@/components/search/SearchFilters";
import useArchieData from "@/hooks/useArchieData";
import useSearchSubmit from "@/hooks/useSearchSubmit";
import {
  AugmentedSearchResponse,
  ClassicSearchResponse,
} from "@/lib/SemanticScholarApi";
import Container from "@/ui/Container";
import Pagination from "@/ui/Pagination";
import SearchArticle from "@/ui/SearchArticle";
import SearchLoading from "@/ui/pending/SearchLoading";

import {
  Box,
  Button,
  Heading,
  Icon,
  IconButton,
  Image,
  Input,
  InputGroup,
  InputRightAddon,
  Stack,
  Text,
  useDisclosure,
} from "@chakra-ui/react";
import { Suspense, useEffect, useMemo, useRef } from "react";
import { Trans, useTranslation } from "react-i18next";
import { IoFilterCircleOutline } from "react-icons/io5";

import {
  Await,
  Form,
  Link,
  useLoaderData,
  useNavigate,
  useNavigation,
} from "react-router-dom";

const Search = () => {
  const { results, page, query } = useLoaderData() as {
    results: Promise<ClassicSearchResponse | AugmentedSearchResponse>;
    page: number;
    query: string | undefined;
  };
  const navigate = useNavigate();
  const navigation = useNavigation();
  const formRef = useRef<HTMLFormElement>(null);
  const handleSubmit = useSearchSubmit(formRef);
  const busy =
    navigation.state === "loading" &&
    navigation.location.pathname === "/search";

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [page, query, busy]);

  const { isOpen, onClose, onOpen } = useDisclosure();
  const { t } = useTranslation();

  const { filterByAnswer, selectedAnswer, archieYO, papers } = useArchieData();

  const filteredArticles = useMemo(() => {
    if (!archieYO || !filterByAnswer) {
      return [];
    }

    const selectedAnswers = Object.entries(selectedAnswer).flatMap(
      ([key, value]) => {
        return value ? key : [];
      }
    ) as ("yes" | "no" | "potentially")[];

    return papers.filter((paper) => {
      return selectedAnswers.some((answer) => {
        return archieYO.answer[answer].includes(paper.corpusId.toString());
      });
    });
  }, [filterByAnswer, selectedAnswer, archieYO, papers]);

  return (
    <Container hasSearch>
      <Box
        mt={8}
        p={[4, 8]}
        display={"flex"}
        flexDirection={"column"}
        alignItems={"center"}
        gap={4}
        w={"100%"}
        h={"100%"}
        borderRadius={24}
        bgColor={"#F2F6F9"}
      >
        {query ? (
          <>
            {" "}
            <Stack direction={"row"} spacing={4}>
              <Heading textAlign={"center"} as={"h1"} size={"lg"}>
                {t("search.title")}
              </Heading>
              <IconButton
                aria-label={"filter"}
                icon={<Icon h={8} w={8} as={IoFilterCircleOutline} />}
                onClick={onOpen}
                variant={"ghost"}
              />
            </Stack>
            {busy ? (
              <SearchLoading />
            ) : (
              <>
                <Suspense fallback={<SearchLoading />}>
                  <Await
                    resolve={results}
                    errorElement={<Text>{t("search.error")}</Text>}
                  >
                    {(
                      results: ClassicSearchResponse | AugmentedSearchResponse
                    ) => {
                      if (results.isAiSearch) {
                        return (
                          <>
                            <Archie data={results.data} />
                            <Box
                              gap={8}
                              p={2}
                              display={"flex"}
                              flexDirection={"column"}
                            >
                              {filterByAnswer
                                ? filteredArticles.map((document) => (
                                    <SearchArticle
                                      key={document.paperId}
                                      {...document}
                                    />
                                  ))
                                : Object.entries(results.data.results).map(
                                    ([key, value]) => (
                                      <SearchArticle key={key} {...value} />
                                    )
                                  )}
                            </Box>
                          </>
                        );
                      }
                      return (
                        <>
                          <Box
                            gap={8}
                            p={2}
                            display={"flex"}
                            flexDirection={"column"}
                          >
                            {results.data.map((document) => (
                              <SearchArticle
                                key={document.paperId}
                                {...document}
                              />
                            ))}
                          </Box>
                          <Pagination
                            total={results.total > 1000 ? 1000 : results.total}
                            current={page}
                            onChange={(currentPage) => {
                              const queryParams = new URLSearchParams(
                                window.location.search
                              );

                              queryParams.set("page", currentPage.toString());
                              navigate({
                                search: queryParams.toString(),
                              });
                            }}
                            resultsPerPage={10}
                          />
                        </>
                      );
                    }}
                  </Await>
                </Suspense>
              </>
            )}
          </>
        ) : (
          <Box
            flexGrow={1}
            display={"flex"}
            flexDir={"column"}
            alignItems={"stretch"}
            gap={8}
            pt={"15%"}
          >
            <Image
              src="/images/logo-full.svg"
              alt="Paperdoc"
              width={300}
              mx={"auto"}
            />
            <Text textAlign={"center"} fontSize={18} fontStyle={"italic"}>
              {t("search.subtitle")}
            </Text>

            <Form
              method="get"
              action="/search"
              ref={formRef}
              onSubmit={(e) => {
                e.preventDefault();
                handleSubmit();
              }}
            >
              <Box display={"flex"} justifyContent={"center"}>
                <InputGroup bgColor={"#fff"} borderRadius={50} maxW={"800px"}>
                  <Input
                    type="text"
                    placeholder={t("search.placeholder")}
                    name="q"
                    id="q"
                    border={"none"}
                    borderRadius={50}
                    h={16}
                    required
                    w={"100%"}
                  />
                  <InputRightAddon
                    bgColor={"#fff"}
                    border={"none"}
                    borderRadius={50}
                    h={16}
                    p={1}
                    pl={0}
                  >
                    <Button
                      h={14}
                      borderRadius={50}
                      variant={"primary"}
                      type="submit"
                    >
                      {t("search.submit")}
                    </Button>
                  </InputRightAddon>
                </InputGroup>
              </Box>
              <Box display={"flex"} justifyContent={"center"}>
                <SearchActions />
              </Box>
            </Form>

            <Text
              textAlign={"center"}
              fontSize={18}
              fontStyle={"italic"}
              sx={{
                a: {
                  color: "brand.500",
                  textDecoration: "underline",
                },
                textWrap: "balance",
              }}
            >
              <Trans i18nKey="search.help" t={t}>
                Parcourez une base de données de +200 millions d'articles
                couvrant tous les domaines scientifiques. Pour en savoir plus,
                allez sur la rubrique
                <Link to={"/help"}>'Comment ça marche?'</Link>.
              </Trans>
            </Text>
          </Box>
        )}
      </Box>
      <SearchFilters isOpen={isOpen} onClose={onClose} />
    </Container>
  );
};

export default Search;

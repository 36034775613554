import { useToDoList } from "@/hooks/useToDoLists";
import { Box, Button, Heading } from "@chakra-ui/react";

import { Trans, useTranslation } from "react-i18next";

const TodosButtons = () => {
  const { t } = useTranslation();

  const { openTodos } = useToDoList();

  return (
    <Box
      display={"flex"}
      flexDirection={"column"}
      justifyContent={"space-between"}
      gap={4}
      bgColor={"#F1F5F8"}
      p={4}
      borderRadius={20}
      overflow={"hidden"}
    >
      <Heading
        as={"h2"}
        size={"md"}
        h={"20%"}
        textAlign={"center"}
        sx={{
          span: {
            color: "brand.500",
          },
        }}
      >
        <Trans t={t} i18nKey={"widgets.tools"}>
          <span>Ma</span> To-Do List
        </Trans>
      </Heading>
      <Button colorScheme="brand" size="md" onClick={openTodos}>
        {t("todos.myTodos")}
      </Button>
    </Box>
  );
};

export default TodosButtons;

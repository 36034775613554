import { defer } from "react-router-dom";
import semanticScholarApi from "../SemanticScholarApi";
import { aiSearch } from "../cloudFunctions";

const searchLoader = async ({ request }: { request: Request }) => {
  const url = new URL(request.url);
  const aiSearchActivated = url.searchParams.get("ai-search") === "true";
  const searchTerm = url.searchParams.get("q") || "";
  const page = url.searchParams.get("page") || "1";
  const max_year = url.searchParams.get("max_year") || "";
  const min_year = url.searchParams.get("min_year") || "";
  const isOpenAccess = url.searchParams.get("isOpenAccess") || null;
  const minCitations = url.searchParams.get("minCitations") || null;
  const fieldsOfStudy = url.searchParams.get("fieldsOfStudy") || null;
  const influential_citation_filter =
    url.searchParams.get("influential") || null;
  const additionalParams: {
    [key: string]: string;
  } = {};

  if (max_year && min_year) {
    additionalParams.year = `${min_year}-${max_year}`;
  }

  if (isOpenAccess) {
    additionalParams.openAccessPdf = "true";
  }

  if (minCitations) {
    additionalParams.minCitationCount = minCitations;
  }

  if (fieldsOfStudy) {
    additionalParams.fieldsOfStudy = decodeURIComponent(fieldsOfStudy);
  }

  let results;
  if (aiSearchActivated) {
    results = new Promise((resolve) => {
      aiSearch({
        query: searchTerm,
        year_range: [
          parseInt(min_year || `${new Date().getFullYear() - 14}`),
          parseInt(max_year || `${new Date().getFullYear()}`),
        ],
        is_open_access: isOpenAccess === "true",
        influential_citation_filter: influential_citation_filter === "true",
        selected_fields_of_study: fieldsOfStudy ? fieldsOfStudy.split(",") : [],
      }).then((res) => {
        resolve({ ...res, isAiSearch: true });
      });
    });
  } else {
    results = new Promise((resolve) => {
      semanticScholarApi.search
        .get("/paper/search", {
          params: {
            query: searchTerm,
            offset: (parseInt(page) - 1) * 10,
            limit: 10,
            fields:
              "title,authors,journal,externalIds,tldr,publicationDate,year,citationCount,corpusId",
            ...additionalParams,
          },
        })
        .then((res) => {
          resolve({ ...res.data, isAiSearch: false });
        });
    });
  }

  return defer({
    results: results,
    page: parseInt(page),
    query: searchTerm,
    isAiSearch: aiSearchActivated,
  });
};

export default searchLoader;

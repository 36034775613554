import {
  ArchieQResponse,
  ArchieSummResponse,
  ArchieYOResponse,
} from "@/lib/archie";
import { SearchResponseItem } from "@/lib/SemanticScholarApi";
import { create } from "zustand";

type Actions = {
  setArchieData: (data: Partial<Omit<ArchieData, "currentQuery">>) => void;
  setCurrentQuery: (query: string) => void;
  setSelectedAnswers: (answers: {
    yes: boolean;
    no: boolean;
    potentially: boolean;
  }) => void;
  toggleFilterByAnswer: () => void;

  reset: () => void;
};

type ArchieData = {
  archieYO: ArchieYOResponse | null;
  archieQ:
    | (Omit<ArchieQResponse, "answer"> & {
        answer: (string | JSX.Element)[];
      })
    | null;
  achieSUMM: ArchieSummResponse | null;
  currentQuery: string;
  papers: SearchResponseItem[];
  language: string;

  cleanedQuery: string;
  additionalQuestions: {
    question: string;
    answer?: (string | JSX.Element)[];
  }[];
  selectedAnswer: {
    yes: boolean;
    no: boolean;
    potentially: boolean;
  };
  filterByAnswer: boolean;
  displayed: {
    aiSearch: boolean;
    insights: boolean;
    summary: boolean;
  };
};

type State = ArchieData;

const initialState: State = {
  archieYO: null,
  archieQ: null,
  achieSUMM: null,
  currentQuery: "",
  papers: [],
  language: "en",
  selectedAnswer: {
    yes: true,
    no: true,
    potentially: true,
  },
  filterByAnswer: false,
  cleanedQuery: "",
  additionalQuestions: [],
  displayed: {
    aiSearch: true,
    insights: true,
    summary: true,
  },
};

const useArchieData = create<State & Actions>()((set) => ({
  ...initialState,
  setArchieData: (data) => set(data),
  setCurrentQuery: (query) => set({ currentQuery: query }),
  setSelectedAnswers: (answers) => set({ selectedAnswer: answers }),
  reset: () => set(initialState),
  toggleFilterByAnswer: () =>
    set((state) => ({ filterByAnswer: !state.filterByAnswer })),
}));

export default useArchieData;

import useSummaryGeneration from "@/lib/hooks/useSummaryGeneration";
import ProgressBar from "@/ui/ia/ProgressBar";
import { Box, Button, CircularProgress, Heading, Text } from "@chakra-ui/react";
import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";

const CreateOpenSummary = (props: {
  corpusId: string;
  loadSummaries: () => Promise<void>;
}) => {
  const { corpusId, loadSummaries } = props;
  const [isLoading, setIsLoading] = useState(false);
  const { t } = useTranslation();
  const {
    currentStep,

    error,

    startOpenSummary,
  } = useSummaryGeneration(async () => {
    await new Promise((resolve) => {
      setTimeout(resolve, 1500);
    });
    await loadSummaries();
  });

  useEffect(() => {
    if (error) {
      setIsLoading(false);
    }
  }, [error]);

  return (
    <Box
      display={"flex"}
      flexDir={"column"}
      alignItems={"center"}
      gap={4}
      w={"100%"}
    >
      {error && (
        <Box>
          <Text textAlign={"center"} color="red.500">
            {error}
          </Text>
        </Box>
      )}
      <Box alignSelf={"stretch"}>
        <ProgressBar textColor="#000" step={currentStep} />
      </Box>

      {currentStep === 0 && (
        <Button
          maxW={300}
          colorScheme="brand"
          isLoading={isLoading}
          onClick={() => {
            setIsLoading(true);
            startOpenSummary(`${corpusId}`);
          }}
        >
          {t("summarize.open")}
        </Button>
      )}
      {currentStep === 1 && (
        <>
          <Heading as={"h2"}>{t("summarize.steps.processing")}</Heading>

          <CircularProgress isIndeterminate color="brand.500" />
          <Text fontSize={"1.5rem"}>{t("summarize.processing")}</Text>
          <Text fontSize={"1.5rem"}>{t("summarize.waitTime")}</Text>
        </>
      )}
      {currentStep === 2 && (
        <>
          <Heading as={"h2"}>{t("summarize.steps.formatting")}</Heading>
          <CircularProgress isIndeterminate color="brand.500" />

          <Text fontSize={"1.5rem"}>{t("summarize.formatting")}</Text>
          <Text fontSize={"1.5rem"}>{t("summarize.waitTime")}</Text>
        </>
      )}
      {currentStep === 3 && (
        <>
          <Heading as={"h2"}>{t("summarize.steps.done")}</Heading>
          <Text fontSize={"1.5rem"}>{t("summarize.done")}</Text>
          <Button
            colorScheme="brand"
            onClick={() => {
              loadSummaries();
            }}
          >
            {t("summarize.refresh")}
          </Button>
        </>
      )}
    </Box>
  );
};

export default CreateOpenSummary;

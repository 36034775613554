import {
  Button,
  Popover,
  PopoverArrow,
  PopoverBody,
  PopoverCloseButton,
  PopoverContent,
  PopoverHeader,
  PopoverTrigger,
} from "@chakra-ui/react";
import { useTranslation } from "react-i18next";

const Beta = () => {
  const { t } = useTranslation();

  return (
    <Popover>
      <PopoverTrigger>
        <Button size={"xs"} borderRadius={4} colorScheme="gray">
          BETA
        </Button>
      </PopoverTrigger>
      <PopoverContent>
        <PopoverArrow />
        <PopoverCloseButton />
        <PopoverHeader>{t("beta.title")}</PopoverHeader>
        <PopoverBody>{t("beta.body")}</PopoverBody>
      </PopoverContent>
    </Popover>
  );
};

export default Beta;

import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  HStack,
  SkeletonText,
  Tag,
  Text,
} from "@chakra-ui/react";
import parseText from "./ArchieQ/TextParser";
import useArchieData from "@/hooks/useArchieData";

import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { runArchieQ } from "@/lib/cloudFunctions";

const ArchieQ = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [sourceCount, setSourceCount] = useState(1);
  const {
    archieQ,
    additionalQuestions,
    setArchieData,
    papers,
    language,
    currentQuery,
  } = useArchieData((state) => state);

  useEffect(() => {
    if (currentQuery === "" || archieQ) {
      return;
    }

    setLoading(true);
    const runArchie = async () => {
      const response = await runArchieQ({
        query: currentQuery,
        papers: papers,
        language: language,
        first_request: true,
      });

      const { parts, currentSource } = parseText(response.data.answer, papers);
      setSourceCount(currentSource);
      setArchieData({
        archieQ: {
          ...response.data,
          answer: parts,
        },
        additionalQuestions: response.data.questions.map((question) => ({
          question: question,
        })),
      });
      setLoading(false);
    };
    runArchie();
  }, [archieQ, currentQuery, papers, language, setArchieData]);

  if (loading) return <SkeletonText w={"100%"} noOfLines={5} spacing={4} />;
  if (!archieQ) return null;
  const loadAdditionalQuestions = async (index: number) => {
    if (index <= 0) return;

    if (additionalQuestions[index].answer) {
      return;
    }

    const response = await runArchieQ({
      query: additionalQuestions[index].question,
      papers: papers,
      language: language,
      first_request: false,
    });

    const { parts, currentSource } = parseText(
      response.data.answer,
      papers,
      sourceCount
    );
    setSourceCount(currentSource);

    setArchieData({
      additionalQuestions: additionalQuestions.map((question, i) =>
        i === index ? { ...question, answer: parts } : question
      ),
    });
  };
  return (
    <Box>
      <Tag mt={1}>
        {t("archie.papersAnalysed", {
          count: papers.length,
        })}
      </Tag>
      <HStack gap={4} flexWrap={"wrap"} mt={4}></HStack>

      <Accordion
        defaultIndex={0}
        allowToggle
        onChange={loadAdditionalQuestions}
      >
        <AccordionItem>
          <AccordionButton
            display={"flex"}
            justifyContent={"space-between"}
            alignItems={"center"}
            gap={4}
          >
            <Text
              textAlign={"left"}
              sx={{
                textWrap: "balance",
              }}
              fontWeight={"bold"}
            >
              {archieQ.questions[0]}
            </Text>
            <AccordionIcon />
          </AccordionButton>
          <AccordionPanel>
            <Text mt={4}>{archieQ.answer}</Text>
          </AccordionPanel>
        </AccordionItem>
        <Text fontWeight={"bold"} as={"h3"} size={"md"} my={4}>
          {t("archie.additional_questions")}
        </Text>
        {additionalQuestions.slice(1).map((question, index) => (
          <AccordionItem key={index}>
            <AccordionButton
              display={"flex"}
              justifyContent={"space-between"}
              alignItems={"center"}
              gap={4}
            >
              <Text
                textAlign={"left"}
                sx={{
                  textWrap: "balance",
                }}
                fontWeight={"bold"}
              >
                {question.question}
              </Text>
              <AccordionIcon />
            </AccordionButton>
            <AccordionPanel>
              {question.answer ? (
                <Text>{question.answer}</Text>
              ) : (
                <SkeletonText noOfLines={5} spacing={4} skeletonHeight={4} />
              )}
            </AccordionPanel>
          </AccordionItem>
        ))}
      </Accordion>
    </Box>
  );
};

export default ArchieQ;

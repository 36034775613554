import useRegistrationForm from "@/hooks/useRegistrationForm";
import { dbIa } from "@/lib/firebase";
import {
  Button,
  Input,
  Link as ChakraLink,
  Text,
  Box,
  Stack,
} from "@chakra-ui/react";
import { doc, getDoc } from "firebase/firestore";
import { useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { Link, useNavigate } from "react-router-dom";

type FormData = {
  email: string;
  password: string;
};

const Credentials = () => {
  const { t } = useTranslation();
  const { setEmail, setPassword, nextStep, email, password } =
    useRegistrationForm();
  const {
    register,
    handleSubmit,
    formState: { errors, isSubmitting },
    setError,
  } = useForm<FormData>({
    defaultValues: {
      email: email,
      password: password,
    },
  });
  const navigate = useNavigate();
  const onSubmit = handleSubmit(async (data) => {
    const _user = await getDoc(
      doc(dbIa, "users", data.email.toLocaleLowerCase())
    );
    if (_user.exists()) {
      console.log("User already exists");
      setError("root", {
        type: "manual",
        message: t("auth.register.userExists"),
      });
      return;
    }
    setEmail(data.email);
    setPassword(data.password);
    nextStep();
    navigate("/register/status");
  });

  return (
    <Box
      as="form"
      display={"flex"}
      flexDir={"column"}
      gap={8}
      w={"clamp(280px, 70%, 500px)"}
      onSubmit={onSubmit}
    >
      {errors.root && <Box color={"red"}>{errors.root.message}</Box>}
      <Input
        type="email"
        placeholder={t("auth.login.email")}
        variant={"auth"}
        {...register("email")}
        required
      />
      {errors.email && <Box color={"red"}>{errors.email.message}</Box>}
      <Input
        type="password"
        placeholder={t("auth.login.password")}
        variant={"auth"}
        {...register("password")}
        required
      />
      {errors.password && <Box color={"red"}>{errors.password.message}</Box>}
      <Button type="submit" variant={"primary"} isLoading={isSubmitting}>
        {t("auth.register.createAccount")}
      </Button>
      <Stack
        direction={"column"}
        spacing={0}
        alignSelf={"center"}
        textAlign={"center"}
      >
        <Text>{t("auth.register.alreadyHaveAccount")} </Text>
        <ChakraLink textDecoration={"underline"} as={Link} to={"/login"}>
          {t("auth.register.signIn")}
        </ChakraLink>
      </Stack>
    </Box>
  );
};

export default Credentials;

import { RouterProvider } from "react-router-dom";
import { ChakraProvider } from "@chakra-ui/react";
import router from "./router.jsx";
import theme from "./theme";
import UserSessionProvider from "./hooks/useUserSession";
import { useEffect, useState } from "react";
import { authIa } from "./lib/firebase.js";
import TodoListContextProvider from "./hooks/useToDoLists.js";

function App() {
  const [isAuthReady, setIsAuthReady] = useState(false);

  useEffect(() => {
    const unsubscribe = authIa.onAuthStateChanged(() => {
      setIsAuthReady(true);

      unsubscribe();
    });
  }, []);
  return isAuthReady ? (
    <>
      <UserSessionProvider>
        <ChakraProvider theme={theme}>
          <TodoListContextProvider>
            <RouterProvider router={router} />
          </TodoListContextProvider>
        </ChakraProvider>
      </UserSessionProvider>
    </>
  ) : null;
}

export default App;

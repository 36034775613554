import { Box, BoxProps, Image } from "@chakra-ui/react";

const AuthContainer = (props: BoxProps) => {
  const { children } = props;
  return (
    <Box
      w={"100vw"}
      h={"100vh"}
      display={"flex"}
      flexDir={"column"}
      gap={8}
      pt={10}
      alignItems={"center"}
      color={"#fff"}
      {...props}
    >
      <Image src="/images/logo-full.svg" alt="Paperdoc" width={200} />
      <Box
        p={8}
        display={"flex"}
        flexDir={"column"}
        borderRadius={24}
        bgImage={"/backgrounds/auth.svg"}
        bgPos={"center"}
        bgSize={"cover"}
        gap={4}
        alignItems={"center"}
        w={["clamp(300px, 90%, 1000px)", "clamp(300px, 70%, 1000px)"]}
        minH={"50vh"}
      >
        {children}
      </Box>
    </Box>
  );
};

export default AuthContainer;

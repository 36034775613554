import { createBrowserRouter } from "react-router-dom";
import * as Sentry from "@sentry/react";
import Home from "./pages/Home";
import Layout from "./Layout";
import homeLoader from "./lib/loaders/homeLoader";
import Article from "./pages/Article";
import {
  articleLoader,
  backupArticleLoader,
} from "./lib/loaders/articleLoader";
import Search from "./pages/Search";
import searchLoader from "./lib/loaders/searchLoader";
import Login from "./pages/auth/Login";
import Building from "./pages/Building";
import ToolBox from "./pages/ToolBox";
import toolboxLoader from "./lib/loaders/toolboxLoader";
import Register from "./pages/auth/register/Register";
import Credentials from "./pages/auth/register/Credentials";
import Status from "./pages/auth/register/Status";
import Domain from "./pages/auth/register/Domain";
import Specialty from "./pages/auth/register/Specialty";
import Identity from "./pages/auth/register/Identity";
import Terms from "./pages/auth/register/Terms";
import ValidateRpps from "./pages/auth/register/ValidateRpps";
import Ia from "./pages/Ia";
import ArticleBackup from "./pages/ArticleBackup";

import Contact from "./pages/Contact";
import ForgottenPassword from "./pages/auth/ForgottenPassword";

import Settings from "./pages/profile/Settings";
import Profile from "./pages/profile/Profile";
import History from "./pages/profile/History";
import Recap from "./pages/profile/Recap";
import AppError from "./ui/AppError";
import Storage from "./pages/Storage";
import storageLoader from "./lib/loaders/storageLoader";
import Pricing from "./pages/Pricing";
import HowItWorks from "./pages/HowItWorks";
import Summaries from "./pages/profile/Summaries";
import OldRegister from "./pages/auth/oldAccounts/OldRegister";
import OldStatus from "./pages/auth/oldAccounts/OldStatus";
import OldDomain from "./pages/auth/oldAccounts/OldDomain";
import OldSpecialty from "./pages/auth/oldAccounts/OldSpecialty";
import OldIdentity from "./pages/auth/oldAccounts/OldIdentity";
import OldTerms from "./pages/auth/oldAccounts/OldTerms";
import OldValidateRpps from "./pages/auth/oldAccounts/OldValidateRpps";

const sentryCreateBrowserRouter =
  Sentry.wrapCreateBrowserRouter(createBrowserRouter);

const router = sentryCreateBrowserRouter([
  {
    path: "/",
    element: <Layout />,
    children: [
      {
        errorElement: <AppError />,
        children: [
          {
            path: "/",

            element: <Home />,
            loader: homeLoader,
          },
          {
            path: "/article/:slug?/:articleId",
            element: <Article />,
            loader: articleLoader,
          },
          {
            path: "/articles/:articleId",
            element: <ArticleBackup />,
            loader: backupArticleLoader,
          },
          {
            path: "/search",
            element: <Search />,
            loader: searchLoader,
          },
          {
            path: "/help",
            element: <HowItWorks />,
          },
          {
            path: "/community",
            element: <Building />,
          },
          {
            path: "/ia",
            element: <Ia />,
          },
          {
            path: "/toolbox",
            element: <ToolBox />,
            loader: toolboxLoader,
          },
          {
            path: "/storage",
            children: [
              {
                index: true,
                element: import.meta.env.DEV ? <Storage /> : <Building />,
                path: "*",
                loader: storageLoader,
              },
            ],
          },
          {
            path: "/profile",
            element: <Profile />,
            children: [
              {
                path: "settings",
                element: <Settings />,
              },
              {
                path: "history",
                element: <History />,
              },
              {
                path: "summaries",
                element: <Summaries />,
              },
              {
                index: true,
                element: <Recap />,
              },
            ],
          },
          {
            path: "/pricing",
            element: <Pricing />,
          },
        ],
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
  {
    path: "/register",
    element: <Register />,
    children: [
      {
        index: true,
        element: <Credentials />,
      },
      {
        path: "status",
        element: <Status />,
      },
      {
        path: "domain",
        element: <Domain />,
      },
      {
        path: "specialty",
        element: <Specialty />,
      },
      {
        path: "identity",
        element: <Identity />,
      },
      {
        path: "terms",
        element: <Terms />,
      },
      {
        path: "validate-rpps",
        element: <ValidateRpps />,
      },
    ],
  },
  {
    path: "/contact",
    element: <Contact />,
  },
  {
    path: "/forgot-password",
    element: <ForgottenPassword />,
  },
  {
    path: "/complete-profile",
    element: <OldRegister />,
    children: [
      {
        index: true,
        path: "status",
        element: <OldStatus />,
      },
      {
        path: "domain",
        element: <OldDomain />,
      },
      {
        path: "specialty",
        element: <OldSpecialty />,
      },
      {
        path: "identity",
        element: <OldIdentity />,
      },
      {
        path: "terms",
        element: <OldTerms />,
      },
      {
        path: "validate-rpps",
        element: <OldValidateRpps />,
      },
    ],
  },
]);

export default router;
